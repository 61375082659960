import React from 'react';
import App from 'next/app';
import { appWithTranslation } from 'themes/modules/I18n'
import { Provider } from 'react-redux'
import { makeStore, setStore } from 'themes/components/Store'
import nextCookie from 'next-cookies'
import withRedux from "next-redux-wrapper";
import 'themes/less/home.less'
import 'themes/less/home-custom.less'
import 'themes/less/admin.less'
import 'themes/less/custom-ant-theme.less';
import '@ngochipx/nhfinder-ant3/reactjs/dist/main.css'
import 'react-lazy-load-image-component/src/effects/blur.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ScrollToTopButton } from 'themes/components/Utils'
import getConfig from 'next/config'
const {publicRuntimeConfig} = getConfig();

class MyApp extends App {

  static async getInitialProps(AppContext) {
    const {ctx} = AppContext
    // calls page's `getInitialProps` and fills `appProps.pageProps`
    const appProps = await App.getInitialProps(AppContext);
    const cookies = nextCookie(ctx)
    ctx.store.dispatch(setStore("auth", cookies.user));
    ctx.store.dispatch(setStore("cookies", cookies));
    ctx.store.dispatch(setStore("publicRuntimeConfig", publicRuntimeConfig));
    return {
      ...appProps,
      auth: cookies.user,
      cookies: cookies
    }
  }

  render() {
    const { Component, pageProps, store } = this.props;
    return (
      <Provider store={store}>
        <Component {...pageProps} />
        <ScrollToTopButton/>
      </Provider>
    );
  }
}

export default withRedux(makeStore)(appWithTranslation(MyApp))